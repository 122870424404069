import {
    getAreaInfoById,//根据解决方案获取分区
} from "@/api/dataSet/solution";
export default {
    data() {
        return {
            // elmentList:
            //     [
            //         {
            //             pcImg: require('./images/pc/banner1.jpg'),
            //             phoneImg: require('./images/mobile/banner1.jpg'),
            //             descList: [
            //                 {
            //                     descName: 'LNG储备行业',
            //                     sort: 1
            //                 }, {
            //                     descName: 'LNG是通过在常压下气态的天然气，经过预处理，脱除重烃、硫化物、氧化碳和水等杂质后，冷却至-162°C，使之凝结成液体。LNG无色无毒无味，是由天然气转变的另一种能源形式，常温下会迅速气化，不会爆炸，也没有污染，是一种清洁、高效的能源。',
            //                     sort: 2
            //                 }],
            //              type: 'image',
            //             sort: 1
            //         },
            //         {
            //             pcImg: require('./images/pc/banner2.jpg'),
            //             phoneImg: require('./images/mobile/banner2.jpg'),
            //             descList: [
            //                 {
            //                     descName: 'LNG装卸车系统区',
            //                     sort: 1
            //                 }],
            //              type: 'image',
            //             sort: 2
            //         },
            //         {
            //             pcImg: require('./images/pc/banner3.jpg'),
            //             phoneImg: require('./images/mobile/banner3.jpg'),
            //             descList: [
            //                 {
            //                     descName: 'LNG储存系统区',
            //                     sort: 1
            //                 }],
            //              type: 'image',
            //             sort: 3
            //         },
            //         {
            //             pcImg: require('./images/pc/banner4.jpg'),
            //             phoneImg: require('./images/mobile/banner4.jpg'),
            //             descList: [
            //                 {
            //                     descName: '蒸发气 (BOG) 处理系统区',
            //                     sort: 1
            //                 }],
            //              type: 'image',
            //             sort: 4
            //         },
            //         {
            //             pcImg: require('./images/pc/banner5.jpg'),
            //             phoneImg: require('./images/mobile/banner5.jpg'),
            //             descList: [
            //                 {
            //                     descName: 'LNG加压气化系统区',
            //                     sort: 1
            //                 }],
            //              type: 'image',
            //             sort: 5
            //         },
            //         {
            //             pcImg: require('./images/pc/banner6.jpg'),
            //             phoneImg: require('./images/mobile/banner6.jpg'),
            //             descList: [
            //                 {
            //                     descName: '防真空补气系统区',
            //                     sort: 1
            //                 }],
            //              type: 'image',
            //             sort: 6
            //         }, {
            //             pcImg: require('./images/pc/banner7.jpg'),
            //             phoneImg: require('./images/mobile/banner7.jpg'),
            //             descList: [
            //                 {
            //                     descName: '调压计量系统区',
            //                     sort: 1
            //                 }],
            //              type: 'image',
            //             sort: 7
            //         },
            //         {
            //             pcImg: require('./images/pc/banner8.jpg'),
            //             phoneImg: require('./images/mobile/banner8.jpg'),
            //             descList: [
            //                 {
            //                     descName: '其他工艺、公用、辅助系统区',
            //                     sort: 1
            //                 }],
            //              type: 'image',
            //             sort: 8
            //         },
            //         {
            //             pcImg: require('./images/pc/banner9.jpg'),
            //             phoneImg: require('./images/mobile/banner9.jpg'),
            //             descList: [
            //                 {
            //                     descName: '新闻',
            //                     sort: 1
            //                 },
            //                 {
            //                     descName: '七腾机器人入选“重庆市两江新区民营企业创新十强”',
            //                     sort: 2
            //                 }],
            //              type: 'image',
            //             sort: 9
            //         },
            //         {
            //             pcImg: require('./images/pc/banner10.jpg'),
            //             phoneImg: require('./images/mobile/banner10.jpg'),
            //             descList: [
            //                 {
            //                     descName: '新闻',
            //                     sort: 1
            //                 },
            //                 {
            //                     descName: '中国建设银行重庆市分行行长李涛一行莅临七腾机器人调研交流',
            //                     sort: 2
            //                 }],
            //              type: 'image',
            //             sort: 10
            //         },
            //         {
            //             pcImg: require('./images/pc/banner11.jpg'),
            //             phoneImg: require('./images/mobile/banner11.jpg'),
            //             descList: [
            //                 {
            //                     descName: '新闻',
            //                     sort: 1
            //                 },
            //                 {
            //                     descName: '七腾机器人“双核”亮相中博会，尽显专精特新“小巨人”风采',
            //                     sort: 2
            //                 }],
            //              type: 'image',
            //             sort: 11
            //         },
            //         {
            //             pcImg: require('./images/pc/banner12.jpg'),
            //             phoneImg: require('./images/mobile/banner12.jpg'),
            //             descList: [
            //                 {
            //                     descName: '新闻',
            //                     sort: 1
            //                 },
            //                 {
            //                     descName: '江西省调研组一行莅临七腾机器人调研考察',
            //                     sort: 2
            //                 }],
            //              type: 'image',
            //             sort: 12
            //         },
            //         {
            //             pcImg: require('./images/pc/banner13.jpg'),
            //             phoneImg: require('./images/mobile/banner13.jpg'),
            //             descList: [
            //                 {
            //                     descName: '新闻',
            //                     sort: 1
            //                 },
            //                 {
            //                     descName: '从专业视角看巡检机器人的创新与应用',
            //                     sort: 2
            //                 }],
            //              type: 'image',
            //             sort: 13
            //         },
            //         {
            //             pcImg: require('./images/pc/banner14.jpg'),
            //             phoneImg: require('./images/mobile/banner14.jpg'),
            //             descList: [
            //                 {
            //                     descName: '新闻',
            //                     sort: 1
            //                 },
            //                 {
            //                     descName: '钢铁行业中七腾机器人的应用',
            //                     sort: 2
            //                 }],
            //              type: 'image',
            //             sort: 14
            //         },
            //     ],
            // solution: {
            //     solutionName: '油储行业',//解决方案名称
            //     solutionDescription: ' 油储行业就是油的储存和运输，在石油工业内部，它是连接油生产、加工、分配、销售诸环节的纽带。原油储运环节主要巡检区域包括处理区、储罐区、外输区、卸油区等。七腾机器人针对油储核心环节进行细分，深入各环节区域存在的巡检难点痛点问题，提出智能化巡检解决方案，提升巡检工作的安全性、及时性和有效性，达到降本增效、减少油储行业企业安全事故的目的！',
            //     solutionPcImg: require('./images/pc/banner1.jpg'),
            //     solutionPhoneImg: require('./images/mobile/banner1.jpg'),
            //     areaList: [{
            //         areaName: '处理区',
            //         areaPcImg: require('./images/pc/banner1.jpg'),
            //         areaPhoneImg: require('./images/pc/banner1.jpg'),
            //         equipmentList: [{
            //             equipmentName: '管道区域',
            //             equipmentPcImg: require('./images/pc/banner1.jpg'),
            //             equipmentPhoneImg: require('./images/pc/banner1.jpg'),
            //         }],//核心设备
            //         painList: [{
            //             painName: '表计识别读数',
            //             painDescription: '查看卸车台液相、气相管的压力表显示是否处于正常范围',
            //             painPcImg: require('./images/pc/banner1.jpg'),
            //             painaPhoneImg: require('./images/pc/banner1.jpg'),
            //         }],//痛点问题
            //         solutionCaseList: [{
            //             solutionCaseName: '图像识别功能读取表计读数、阀门开关状态',
            //             solutionCasePcImg: require('./images/pc/banner1.jpg'),
            //             solutionCaseImg: require('./images/pc/banner1.jpg'),
            //         }],//解决方案
            //     }],//区域
            //     caseList: [{
            //         caseName: '七腾机器人入选“重庆市两江新区民营企业创新十强”',
            //         casePcImg: require('./images/pc/banner1.jpg'),
            //         casePhoneImg: require('./images/pc/banner1.jpg'),
            //     }],//应用案例
            // },
            areaObj: {},
        }
    },
    watch: {
        "$route.query.solutionId": {
            handler: function (val, oldVal) {
                this.getAreaInfoById();
            },
        }
    },
    mounted() {
        this.$store.dispatch("pageset/getbannerIndex", 0);
        document.documentElement.style.setProperty('--var-primary-color', '#fff');
        this.getAreaInfoById();
    },
    methods: {
        openDetail(areaId) {
            this.$router.push({
                path: "/en/areaDetail",
                query: {
                    areaId: areaId,
                },
            })
        },
        async getAreaInfoById() {
            let res = await getAreaInfoById(this.$route.query.solutionId);
                if (res.code === 200) {
                    this.areaObj = res.data;
                }

        },
    },
}