<template>
  <!-- 解决方案 -->
  <div class="solution">
    <div class="banner">
      <img
        :src="areaObj.solutionPcImg ? areaObj.solutionPcImg : ''"
        v-if="this.$store.state.pageset.pcshow"
      />
      <img
        :src="areaObj.solutionPhoneImg ? areaObj.solutionPhoneImg : ''"
        v-else
      />
      <div class="dec" v-animate="'queue-bottom'">
        <div class="dec-con">
          <p class="p1">{{ areaObj.solutionName }}</p>
          <p class="p2">
            {{ areaObj.solutionDescription }}
          </p>
        </div>
      </div>
    </div>
    <!-- 内容 -->
    <div class="solution-content">
      <!-- 区域 -->
      <p class="content-title">Regional division</p>
      <div class="content-area">
        <div
          v-for="(item, index) in areaObj.webControlSolutionAreaList"
          :key="index"
          class="content-area-item"
          @click="openDetail(item.areaId)"
        >
          <div class="content-area-img">
            <img
              :src="item.areaPcImg ? item.areaPcImg : ''"
              v-if="$store.state.pageset.pcshow"
            />
            <img :src="item.areaPhoneImg ? item.areaPhoneImg : ''" v-else />
          </div>
          <div class="content-area-dec">
            <p class="p1" v-animate="'queue-bottom'">{{ item.areaName }}</p>
            <p
              class="p2"
              v-animate="'queue-bottom'"
              @click="openDetail(item.areaId)"
            >
              more<i
                class="el-icon-arrow-right"
                v-if="$store.state.pageset.pcshow"
              ></i>
            </p>
          </div>
          <div class="content-area-remark" v-if="item.remark">
            <el-tooltip class="item" effect="dark" placement="top">
              <div slot="content" class="tooltip-content">
                {{ item.remark }}
              </div>
              <p class="p1" v-animate="'queue-bottom'">{{ item.remark }}</p>
            </el-tooltip>
          </div>
        </div>
      </div>
      <!-- 应用案例:屏蔽应用案例后续同步新闻 -->
      <p class="content-title" v-if="areaObj.webControlNewsList.length">
        应用案例
      </p>
      <div class="content-case" v-if="areaObj.webControlNewsList.length">
        <div
          v-for="(item, index) in areaObj.webControlNewsList"
          :key="index"
          class="content-case-item"
        >
          <div class="case-img" v-if="item.type === 'image'">
            <img :src="item.newsPcImg" v-if="$store.state.pageset.pcshow" />
            <img :src="item.newsPhoneImg" v-else />
          </div>
          <div class="case-img" v-else>
            <video
              autoplay="false"
              muted
              preload
              class="video"
              :src="item.newsPcImg"
              v-if="$store.state.pageset.pcshow"
            ></video>
            <video
              autoplay="false"
              muted
              preload
              class="video"
              :src="item.newsPhoneImg"
              v-else
            ></video>
          </div>
          <div class="content-case-dec">
            <p class="p1" v-animate="'queue-bottom'">
              <!-- {{ item.name }} -->
              新闻
            </p>
            <p class="p2" v-animate="'queue-bottom'">
              {{ item.name }}
            </p>
            <a
              class="p3"
              v-animate="'queue-bottom'"
              target="_blank"
              :href="item.link"
            >
            Learn more<i class="el-icon-arrow-right"></i>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import solutionArea_en from "./solutionArea_en.js";
export default solutionArea_en;
</script>
<style lang="scss" scoped>
@import "~@/assets/style/common.scss";
@import "../../commonStyle/solutionArea.scss";

.banner {
  .dec {
    height: unset;
    min-height: 180px;
    .dec-con {
      width: 1069px;
      font-family: Microsoft YaHei !important;
      color: white;
      .p1 {
        font-size: 30px;
        margin-bottom: 10px;
      }
      .p2 {
        font-size: 14px;
      }
    }
  }
}

.tooltip-content {
  width: 500px;
}

@media screen and (max-width: 750px) {
  .banner {
    height: 110vh !important;
    .dec {
      height: 360px !important;
      bottom: 400px !important;
      .dec-con {
        width: unset !important;
      }
    }
  }
}
</style>
