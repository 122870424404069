//页面元素设置
import request from '@/utils/request';

// 获取解决方案
export function getSolution(language) {
    return request({
        url: `/control/WebControlSolution/list?language=${language}`,
        method: 'get'
    });
}
// 新增解决方案
export function addSolution(data) {
    return request({
        url: `/control/WebControlSolution`,
        method: 'post',
        data
    });
}

// 修改解决方案
export function editSolution(data) {
    return request({
        url: `/control/WebControlSolution`,
        method: 'put',
        data
    });
}

// 删除解决方案
export function deleteSolution(solutionIds) {
    return request({
        url: `/control/WebControlSolution/${solutionIds}`,
        method: 'delete'
    });
}

// 根据解决方案获取分区
export function getAreaInfoById(solutionId) {
    return request({
        url: `/control/WebControlSolution/${solutionId}`,
        method: 'get'
    });
}

// 根据分区获取配置信息
export function getSetInfoByAreaId(areaId) {
    return request({
        url: `/control/WebControlSolutionArea/${areaId}`,
        method: 'get'
    });
}

//新增解决方案区域
export function addSolutionArea(data) {
    return request({
        url: `/control/WebControlSolutionArea`,
        method: 'post',
        data
    });
}

//修改解决方案区域
export function editSolutionArea(data) {
    return request({
        url: `/control/WebControlSolutionArea`,
        method: 'put',
        data
    });
}

//获取解决方案区域详细信息
export function getAreaDetail(areaId) {
    return request({
        url: `/control/WebControlSolutionArea/${areaId}`,
        method: 'get'
    });
}

// 删除解决方案区域
export function deleteSolutionArea(areaId) {
    return request({
        url: `/control/WebControlSolutionArea/${areaId}`,
        method: 'delete'
    });
}
